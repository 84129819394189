<template>
  <go-back :title="`EDITAR AVISO ${id}`"></go-back>

  <custom-form
    :editMode="true"
    :disabled="disabled"
    :edit-id="id"
    @enviar="submit"
  ></custom-form>
</template>

<script>
import { defineComponent, ref } from "vue";
import router from "@/router";
import axios from "axios";
import { apiPatchAviso } from "@/router/endpoints";
import {
  formatDataAvisoPago,
  objectToFormData,
  showError,
  showToast,
  checkFormAviso
} from "@/Functions";

import CustomForm from "@/components/PanelAvisosForm.vue";
import GoBack from "@/components/GoBack.vue";

export default defineComponent({
  components: {
    CustomForm,
    GoBack,
  },
  setup() {
    const id = router.currentRoute.value.params.id.toString();
    let disabled = ref(false);

    const submit = (aviso) => {
      aviso = formatDataAvisoPago(aviso);
      let form_data = objectToFormData(aviso);

      disabled.value = true;

      if (checkFormAviso(aviso)) {
        axios
          .patch(apiPatchAviso(id), form_data)
          .then(() => {
            showToast("Aviso modificado con éxito", "success");
            router.push({ name: "avisos-de-pago" });
          })
          .catch((err) => {
            showError(err);
          })
          .finally(() => {
            disabled.value = false;
          });
      } else {
        disabled.value = false;
      }
    };

    return { id, submit, disabled };
  },
});
</script>
